.col {
	position: relative;
}

.valign-wrapper .valign {
    margin: 0 auto;
}

.secondfont {
	font-family: $secondfont;
}

.thirdfont {
	font-family: $thirdfont;
}

.center {
		margin: 0 auto;
		display: block;
		float: none !important;
}

#sidenav-overlay {
	display: none;
}

.btn, .btn-flat {
	&.center {
		margin: 0 auto;
		display: block;
	}
}

.location-text {
	padding: 90px;
	& h3 {
		font-size: 2rem;
		font-weight: 700;
	}
	& .divider {
		width: 50%;
		margin: 0 auto;
	}
}





.section {
	display: block;
	width: 100%;
	overflow: hidden;
	&.head {
		position: relative;
		height: 600px;
		padding: 0;
		& > .row {
			height: 100%;
			& .col {
				height: 100%;
				padding: 0;
			}
		}

	}
	&.contact {
		height: 350px;
		padding: 0;
		& > .row {
			height: 100%;
			& .col {
				height: 100%;
			}
		}
		& .concierge {
			@include background-image('/img/layout/inside1.jpg');

		}
		& .concierge, .mailing{
			& .btn-flat {
				background-color: $darkgrey;
				width: 210px;
				&:hover {
					background-color: #4e4e4e;
				}
			}
		}
		& .mailing {
			& .btn-flat {
				margin-top: 10px;
			}
			& p {
				color: #aaa;
				font-size: 1.4rem;
				margin: 0;
			}
		}
	}
	&.bottom-nav {
		height: 250px;
		padding: 0;
		& > .row {
			height: 100%;
			& .col {
				height: 100%;
			}
		}
		& .social {
			& .btn-floating {
				border: 2px solid $darkgrey;
				width: 107px;
    			height: 107px;
    			line-height: 107px;
    			margin: 0 20px;
				& i {
					color: $darkgrey;
					position: absolute;
				    left: -2px;
				    top: -2px;
				    line-height: 107px;
				    font-size: 2.5rem;
				}
			}
		}
		& .links {

			& .btn-flat {
				border: 2px solid #666;
				color: #666;
				height: 39px;
				font-size: 2rem;
				margin: 0 20px;
			}

		}
	}
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	&.dark {
		@include dark-blue-overlay(0.7);
	}
	&.light {
		@include light-blue-overlay(0.7);
	}
}

footer.page-footer {
	padding: 0;
	margin: 0;
	overflow: hidden;
	& .footer-copyright {
		background-color: #aaacb7;
		& p {
			margin: 0;
		}
	}
}
