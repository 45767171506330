
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,300i,400,400i,600);


// Variables
@import "variables";

// Materialize
@import "node_modules/materialize-css/sass/materialize";

// Slick Slider
@import "slick";

// Structure
@import "header";
@import "sections";
@import "pages";
@import "stores";
@import "brands";
@import "hover";
@import "form";
@import "buttons";
@import "queries";

// Modal
@import "node_modules/remodal/dist/remodal";
@import "node_modules/remodal/dist/remodal-default-theme";


//banners
.banner {
  &.coupon-highlight {
    width: 100%;
    background-color: #3976a5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px 0;
    margin-bottom: 0px;
    margin-top: 94px;
    & .coupon-highlight-button {
      font-size:1.8rem;
      background-color: #fff;
      color: #3976a5;
      border-radius: 12px;
      padding: 5px 15px;
      border: 1px solid #fff;
      &:hover {
        background-color: #3976a5;
        color: #fff;
      }
    }
    & h4 {
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      width: 100%;
      & a {
        color: #aaa;
      }
    }
  }
}
