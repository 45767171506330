.display-small {
	display: none;
}
.img-large {
	display: block;
}
.img-small {
	display: none;
}
@media screen and (max-width: 1600px) {
	html {
		font-size: 14px;
	}
	.section {
		&.head {
			&#hawthorn-head {
				height: 450px;
			}
			&#gurnee-head {
				height: 450px;
			}

		}
		&.brand-products {
    		padding: 10px 0 60px 0;
    		& h3 {
    			margin-bottom: 30px;
    		}
		}
		&.brands {
    		padding: 10px 0 60px 0;
    		& h3 {
    			margin-bottom: 30px;
    		}
		}
	}


	.section {
		&.head {
			&#aquamaster-head {
				height: 450px;

			}
			&#benchmark-head {
				height: 450px;

			}
			&#bentelli-head {
				height: 450px;

			}
			&#bulova-head {
				height: 450px;

			}
			&#citizen-head {
				height: 450px;

			}
			&#danielwellington-head {
				height: 450px;

			}
			&#frankreubel-head {
				height: 450px;

			}
			&#gshock-head {
				height: 450px;

			}
			&#kama-head {
				height: 450px;

			}
			&#luminox-head {
				height: 450px;

			}
			&#nataliek-head {
				height: 450px;

			}

			&#raymond-weil-head {
				height: 450px;

			}
			&#tissot-head {
				height: 450px;

			}
			&#agni-head {
				height: 450px;

			}
			&#scott-kay-head {
				height: 450px;

			}

		}
	}


	.section.head .right-block {
		height: 50%;
	}
	.section.brands .slick-slide .brand {
		padding: 0 80px;
	}

	.section.head .right-block {
	    right: 200px;
	}
}

@media screen and (max-width: 1500px) {
		.section.brand-products#raymond-weil .fifth.product {
			width: 33.33333%;
			height: 520px;
		}

		$height: 400px;
		$width: 33.33333%;
		.section.brand-products {
			&#agni {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#aquamaster {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#benchmark {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#bentelli {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#bulova {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#citizen {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#danielwellington {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#frankreubel {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#gshock {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#kama {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#luminox {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#nataliek {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#pandora {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#tissot {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#scott-kay {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
		}



		.section.brands .slick-slide .brand {
			padding: 0 70px;
		}

		.col.about-block {
			height: 600px;
		}
	}

@media screen and (max-width: 1400px) {
	html {
		font-size: 12px;
	}
	.section {
		&.head {
			&#hawthorn-head {
				height: 400px;
			}
			&#gurnee-head {
				height: 400px;
			}
			&#raymond-weil-head {
				height: 400px;
			}

		}
		&.brand-products {
    		padding: 10px 0 60px 0;
    		& h3 {
    			margin-bottom: 30px;
    		}
		}
		&.brands {
    		padding: 10px 0 60px 0;
    		& h3 {
    			margin-bottom: 30px;
    		}
		}
	}
	.section.brands .slick-slide .brand {
		padding: 0 60px;
	}

	.section.head .right-block {
	    right: 100px;
	}

}

@media screen and (max-width: 1200px) {
	.grid {
		& .brick {
			max-width: 600px;
			& img {
				width: 100%;
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	.section.brands .slick-slide .brand {
	    padding: 0 30px;
	}

	.section.head .right-block {
	    right: 50px;
	}

	.col.about-left {
		padding: 60px 150px;
	}

	.col.about-right {
		& .overlay {
			padding: 60px 150px;
		}
	}

	.grid {
		& .brick {
			max-width: 400px;
		}
	}
}

@media screen and (max-width: 1024px) {

	.row .col.s7.map {
		width: 100%;
	}
	.row .col.s5.location-blocks {
		width: 100%;
		height: 200px;
		& .half {
			width: 50%;
			height: 100%;
			float: left;
		}
	}

	//FOOTER CONTACT SECTION
	.row .col.s6.mailing {
		float: left;
	}
	.row .col.s6.concierge {
		float: none;
	    background-image: none;
		background-color: #fff !important;
		& .overlay {
			height: 250px;
		}
	}
	.location-text {
    	padding: 0px;
	}
	.section.contact {
		height: auto;
		& > .row {
			height: auto;
			overflow: hidden;
			margin: 0;
			& > .col {
    			height: 250px !important;
			    display: block;
			    padding-top: 40px;
			}
		}

	}

	//FOOTER SITE LINKS
	.row .col.s6.concierge, .row .col.s6.mailing, .row .col.s5.social, .row .col.s7.links {
		width: 100%;
	}
	.row .col.s5.social, .row .col.s7.links {
		height: 300px;
	}


	//STORE PAGE PRODUCTS
	.section.products {
    	& .row .col.m4.product {
    		width: 50%;
    		height: 360px;
    		&.product-hide {
    			display: none;
    		}
    	}
	}
	.section.bottom-nav {
	    height: auto !important;
	    & .row {
	    	margin-bottom: 0;
	    }
	}
	/* .section.head {
		height: auto !important;
		& .row {
	    	margin-bottom: 0;
	    }
	} */
	.row .col.s7.map {
		height: 500px;
	}



	.row .col.s5.location-blocks {
    	position: absolute;
	}

	.row .col.s7.map {
	    position: absolute;
	    top: 200px;
	}

	.section.head {
    	height: 700px;
	}

	.section.head#hawthorn-head, .section.head#gurnee-head {
	    height: 400px !important;
	}

	.section.head .right-block {
	    	right: 20px;
	    	width: 350px;
	}


	.row .col.m6.about-block {
		width: 100%;
		float: none;
		height: auto;
	    display: block;
	    overflow: hidden;
	}
	.row .col.m6.about-right {
		& .overlay {
			height: auto;
			display: block;
    		position: relative;
		}
	}


	.info-float {
	 	position: fixed;
	 	bottom: 0;
	 	right: 0;
	 	width: 100%;
	 	max-height: 30px;
	 	& span {
	 		width: 100%;
	 		font-size: 1rem;
	 		padding: 5px 0;
	 		border: none;
	 	}
	}

	.page-footer {
		& .container {
			padding-bottom: 30px;
		}
	}

}





@media screen and (max-width: 900px) {
	.section.brand-products#raymond-weil .fifth.product {
		width: 50%;
		height: 700px;
		margin: 0 auto;
		float: none;
	}


		$height: 300px;
		$width: 50%;
		.section.brand-products {
			&#agni {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#aquamaster {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#benchmark {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#bentelli {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#bulova {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#citizen {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#danielwellington {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#frankreubel {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#gshock {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#kama {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#luminox {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#nataliek {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#pandora {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#tissot {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
			&#scott-kay {
				& .fifth.product {
					width: $width;
					height: $height;
				}
			}
		}

	.section.head#hawthorn-head, .section.head#gurnee-head{
	    height: 350px !important;
	}

	.section.head .right-block {

	    	right: 10px;
	    	width: 300px;
	}
}

@media screen and (max-width: 768px) {


	.row .col.s5.location-blocks {
    	height: 400px;
	}

	.row .col.s7.map {
	    top: 400px;
	}

	.section.head {
    	height: 900px;
	}

	.row .col.s5.location-blocks .half {
		width: 100%;
		height: 50%;
	}

	nav .brand-logo {
	    width: 80%;
	    & span {
	    	display: none;
	    }
	}


	.section {
		&.head {
			&#gurnee-head {
				background-image: url(/img/loc/gurnee/head-mob.jpg);
			}
			&#hawthorn-head {
				background-image: url(/img/loc/hawthorn/head-mob.jpg);
			}
		}
	}

	.section.head .right-block {
	    	display: none;
	}

	.section.brands .slick-slide .brand {
	    padding: 0 20px;
	}

	nav .brand-logo .logo {
		padding: 6px 0 10px 0;
	}


	nav.header-nav .nav-wrapper {
		padding: 0 5px;
	}
	.top-bar {
		display: none;
	}
	.section.head {
		margin-top: 0px;
	}


	.section .links .footer-links a {
		text-align: center;
		clear: both;
		width: 100%;
		display: block;
		margin-bottom: 10px;
		font-size: 1.4rem;
		&:after {
			display: none;
		}
	}

	.location-text {
    	padding: 10px;
	}

	.section.bottom-nav .links .btn-flat {
		margin: 20px auto;
		float: none;
		display: block;
	}

	.page.location {
	    padding-top: 64px;
	}

	.grid {
		& .brick {
			max-width: 300px;
		}
	}
}

@media screen and (max-width: 600px) {
	.img-large {
		display: none;
	}
	.img-small {
		display: block;
	}
	.section.head {
		margin-top: 0px;
	}
	.page.location {
	    padding-top: 56px;
	}
	.info-header {
		display: none;
	}
	.section.products {
	    & .row .col.m4.product {
    		height: 400px;
    		width: 100%;
    		&.product-hide {
    			/* display: block !important; */
    		}
    	}
	}
	.display-small {
		display: block;
	}
	&.products {
    		& h3 {
    			margin-bottom: 30px;
    		}
		}

	.corner-block {
		display: none;
	}

		.section {
				&.head {
					&#aquamaster-head {
						height: 350px;
						@include background-image('/img/comp/aquamaster/aqua-mobile.jpg');
					}
					&#benchmark-head {
						height: 350px;
						@include background-image('/img/comp/benchmark/benchmark-mobile.jpg');
					}
					&#bentelli-head {
						height: 350px;
						@include background-image('/img/comp/bentelli/bentelli-mobile.jpg');
					}
					&#bulova-head {
						height: 350px;
						@include background-image('/img/comp/bulova/bulova-mobile.jpg');
					}
					&#citizen-head {
						height: 350px;
						@include background-image('/img/comp/citizen/citizen-mobile.jpg');
					}
					&#danielwellington-head {
						height: 350px;
						@include background-image('/img/comp/danielwellington/dw-mobile.jpg');
					}
					&#frankreubel-head {
						height: 350px;
						@include background-image('/img/comp/frankreubel/frank-mobile.jpg');
					}
					&#gshock-head {
						height: 350px;
						@include background-image('/img/comp/gshock/gshock-mobile.jpg');
					}
					&#kama-head {
						height: 350px;
						@include background-image('/img/comp/kama/kama-mobile.jpg');
					}
					&#luminox-head {
						height: 350px;
						@include background-image('/img/comp/luminox/luminox-mobile.jpg');
					}
					&#nataliek-head {
						height: 350px;
						@include background-image('/img/comp/nataliek/nataliek-mobile.jpg');
					}

					&#raymond-weil-head {
						height: 350px;
						@include background-image('/img/comp/raymondweil/rw-mobile.jpg');
					}
					&#tissot-head {
						height: 350px;
						@include background-image('/img/comp/tissot/tissot-mobile.jpg');
					}
					&#agni-head {
						height: 350px;
						@include background-image('/img/comp/agni/head.jpg');
					}
					&#scott-kay-head {
						height: 500px;
						background-size: contain !important;
						background-position: top center;
						@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/SK_Mobile_3.jpg');
					}

				}
			}
}

@media screen and (max-width: 550px) {

	.section.products {
	    width: 100%;
	    & .row .col.m4.product {
    		height: 300px;
    	}
	}

	.section.brand-products#raymond-weil .fifth.product {
		width: 100%;
		height: 500px;
		margin: 0 auto;
		float: none;
	}

	$height: 300px;
	$width: 100%;
	.section.brand-products {
		&#agni {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#aquamaster {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#benchmark {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#bentelli {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#bulova {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#citizen {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#danielwellington {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#frankreubel {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#gshock {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#kama {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#luminox {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#nataliek {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#pandora {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#tissot {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
		&#scott-kay {
			& .fifth.product {
				width: $width;
				height: $height;
			}
		}
	}


	.col.about-left {
		padding: 20px 20px;
	}

	.col.about-right {
		& .overlay {
			padding: 20px 20px;
		}
	}

}

@media screen and (max-width: 500px) {

	.section.brand-products#raymond-weil .fifth.product {
		width: 100%;
		height: 500px;
		margin: 0 auto;
		float: none;
	}


	.section.brands .slick-slide .brand {
	    padding: 0 60px;
	}

	.section.bottom-nav .links .btn-flat {
		font-size: 1.4rem;
	}

	.row .col.s7.map {
    	height: 600px;
	}

	nav .brand-logo .logo {
		padding: 12px 0 16px 0;
	}

	.btn-flat.social-btn {
		width: 100%;
		margin: 0 auto;
	}

	.location-text {
		padding-top: 0px;
	}

	form {
		& .row .col.m6 {
			width: 100%;
		}
		& .row .col.m12 {
			width: 100%;
		}
	}

	.grid {
		& .brick {
			max-width: 500px;
		}
	}

}


@media screen and (max-width: 430px) {

	.section.products {
	    width: 100%;
	    & .row .col.m4.product {
    		height: 275px;
    	}
	}

	.info-float span {
	    font-size: 0.7rem;
	}

	.section.head#hawthorn-head, .section.head#gurnee-head, .section.head#raymond-weil-head  {
	    height: 275px !important;
	}

}

@media screen and (max-width: 375px) {

	.section.products {
	    & .row .col.m4.product {
    		height: 240px;
    	}
	}
}
