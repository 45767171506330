html {
	overflow-x: hidden;
}
.header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	height: auto;
}

.top-bar {
	background-color: #3976a5;
	width: 100%;
	height: 30px;
	padding-right: 20px;
	& span, a {
		color: #fff;
		line-height: 30px;
	}
}

.side-nav {
	width: 200px;
	background-color: #f1f1f1;
	& .collapsible-header {
		overflow: hidden;
		height: 48px;
	}
	&  li > a > i.material-icons {
		float: right;
	}
}

nav.header-nav {
	background-color: $darkgrey;
	font-weight: 400;
	& .nav-wrapper {
		padding: 0 20px;
	}
	& .brand-logo {
		text-transform: uppercase;
	}
	& ul a:hover {
    	background-color: rgba(255, 255, 255, 0.2);
	}
}

nav .brand-logo {
    position: relative;
    height: 64px;
    top: -3px;
    & .logo {
    	max-height: 100%;
    	padding: 5px 0 5px 0;
    	display: inline-block;
    	vertical-align: middle;
    }
    & span {
		font-size: 1.6rem;
		height: 64px;
		line-height: 64px;
		display: inline-block;
		padding-left: 10px;
    }
}

.half {
	height: 50%;
	position: relative;
	&.top {
		@include background-image('/img/loc/hawthorn.jpg');
	}
	&.bottom {
		@include background-image('/img/loc/gurnee.jpg');
	}
}

nav .nav-wrapper i {
	margin-left: 3px;
}

#nav-main {
	& .dropdown-content li > a, .dropdown-content li > span {
	    color: #e4e4e4;
	    background-color: #333;
	    &:hover {
	    	color: #333;
	    	background-color: #fff;
	    }
	}
}

.info-float {
 	position: fixed;
 	bottom: 10%;
 	right: 0;
 	width: 300px;
 	height: auto;
 	z-index: 20;
 	background-color: rgba(57,118,165, 0.8);
 	padding: 5px;
 	/* opacity: 0; */
 	& span {
 		color: #fff;
 		text-align: center;
 		width: 100%;
 		display: block;
 		font-size: 1.4rem;
 		text-transform: uppercase;
 		padding: 5px;
 		border: 1px solid #fff;
 	}
}
