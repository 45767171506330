
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Fonts
$mainfont: 'Montserrat', sans-serif;
$secondfont: 'Lato', sans-serif;
$thirdfont: 'Cormorant Garamond', serif;

// Colors
$darkgrey: #222;
$darklightgrey: #eeeeee;
$lightgrey: #f5f5f5;

$lightblue: #6eaace;
$darkblue: #2c579b;
$flat-blue: #879caf;

// Mixins
@mixin light-blue-overlay($op) {
	background-color:rgba(110, 170, 206, $op);
}
@mixin dark-blue-overlay($op) {
	background-color:rgba(44, 87, 155, $op);
}

@mixin background-image($url) {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url($url);
}

// Universal Element Styles
body {
	font-family: $mainfont;
}
