.btn-flat.social-btn {
	height: auto;
	padding-top: 13px;
	margin: 0 20px;
	& i {
		font-size: 2.3rem;
	}
	& span {
		color: #666;
	}
}