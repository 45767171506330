.section {
	&.head {
		&#aquamaster-head {
			height: 500px;
			@include background-image('/img/comp/aquamaster/aqua.jpg');
		}
		&#benchmark-head {
			height: 500px;
			@include background-image('/img/comp/benchmark/benchmark.jpg');
		}
		&#bentelli-head {
			height: 500px;
			@include background-image('/img/comp/bentelli/bentelli.jpg');
		}
		&#bulova-head {
			height: 500px;
			@include background-image('/img/comp/bulova/bulova.jpg');
		}
		&#citizen-head {
			height: 500px;
			@include background-image('/img/comp/citizen/citizen.jpg');
		}
		&#danielwellington-head {
			height: 500px;
			@include background-image('/img/comp/danielwellington/dw.jpg');
		}
		&#frankreubel-head {
			height: 500px;
			@include background-image('/img/comp/frankreubel/frank.jpg');
		}
		&#gshock-head {
			height: 500px;
			@include background-image('/img/comp/gshock/gshock.jpg');
		}
		&#kama-head {
			height: 500px;
			@include background-image('/img/comp/kama/kama.jpg');
		}
		&#luminox-head {
			height: 500px;
			@include background-image('/img/comp/luminox/luminox.jpg');
		}
		&#nataliek-head {
			height: 500px;
			@include background-image('/img/comp/nataliek/nataliek.jpg');
		}
		&#pandora-head {
			height: auto;
			width: 100%;
			display: block;
			overflow: hidden;
			& img {
				width: 100%;
			}
		}
		&#raymond-weil-head {
			height: 500px;
			@include background-image('/img/comp/raymondweil/rw.jpg');
		}
		&#tissot-head {
			height: 500px;
			@include background-image('/img/comp/tissot/tissot.jpg');
		}
		&#agni-head {
			height: 500px;
			@include background-image('/img/comp/agni/head.jpg');
		}


		&#versace-head {
			height: 500px;
			@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/versace-header.jpg');
		}

		&#scott-kay-head {
			height: 500px;
			@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/SK_WebBanner_1.jpg');
		}
		
	}
}
.corner-block {
	background-color: rgba(0,0,0, 0.7);
	position: absolute;
	padding: 20px;
	left: 0;
	bottom: 0;
	& h3 {
		font-size: 2.92rem;
		margin-top: 0;
		font-family: $thirdfont;
	}
	& span {
		font-family: $secondfont;
		font-weight: 300;
		width: 100%;
		font-size: 1.4rem;
		display: block;
	}
}

$scale: 1.1;

.product-block {
	float: left;
	transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    overflow: hidden;
}

.product-block-hover {
	float: left;
	transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    overflow: hidden;
    &:hover {
	  transform: scale($scale);
	  -moz-transform: scale($scale);
	  -webkit-transform: scale($scale);
	  -o-transform: scale($scale);
	  -ms-transform: scale($scale); /* IE 9 */
	  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=$scale, M12=0, M21=0, M22=$scale, SizingMethod='auto expand')"; /* IE8 */
	   filter: progid:DXImageTransform.Microsoft.Matrix(M11=$scale, M12=0, M21=0, M22=$scale, SizingMethod='auto expand');
	}
}

.section.brand-products {
	padding: 40px 0 60px 0; 
	& h3 {
		margin-bottom: 60px;
	}
	&#raymond-weil {
		& .fifth.product {
			margin-bottom: 40px;
			width: 20%;
			height: 480px;
			@extend .product-block-hover;
			&#product1 {
				@include background-image('/img/comp/raymondweil/freelancer-2710.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/raymondweil/freelancer-2740.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/raymondweil/freelancer-7730.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/raymondweil/maestro-2227.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/raymondweil/maestro-2237.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/raymondweil/noemia-5124.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/raymondweil/noemia-5132.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/raymondweil/noemia-5132-1.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/raymondweil/tango-5391.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/raymondweil/tango-5391-1.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/raymondweil/tango-5591.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/raymondweil/toccata-5388.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/raymondweil/toccata-5388-1.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/raymondweil/toccata-5588.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/raymondweil/toccata-5988.jpg');
			}

			
		}
	}



	&#agni {
		& .fifth.product {
			width: 20%;
			height: 400px;
			@extend .product-block;
			&#product1 {
				@include background-image('/img/comp/agni/1/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/agni/2/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/agni/3/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/agni/1/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/agni/2/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/agni/3/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/agni/1/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/agni/2/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/agni/3/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/agni/1/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/agni/1/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/agni/2/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/agni/3/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/agni/1/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/agni/2/image15.jpg');
			}

			&#product16 {
				@include background-image('/img/comp/agni/3/image16.jpg');
			}
			&#product17 {
				@include background-image('/img/comp/agni/1/image17.jpg');
			}
			&#product18 {
				@include background-image('/img/comp/agni/2/image18.jpg');
			}
			&#product19 {
				@include background-image('/img/comp/agni/3/image19.jpg');
			}
			&#product20 {
				@include background-image('/img/comp/agni/1/image20.jpg');
			}

		
		}
	}



	&#aquamaster {
		& .fifth.product {
			width: 20%;
			height: 400px;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('/img/comp/aquamaster/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/aquamaster/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/aquamaster/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/aquamaster/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/aquamaster/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/aquamaster/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/aquamaster/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/aquamaster/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/aquamaster/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/aquamaster/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/aquamaster/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/aquamaster/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/aquamaster/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/aquamaster/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/aquamaster/image15.jpg');
			}

		
		}
	}



	&#benchmark {
		& .fifth.product {
			width: 20%;
			height: 400px;
			@extend .product-block-hover;
			margin-bottom: 20px;
			&#product1 {
				@include background-image('/img/comp/benchmark/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/benchmark/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/benchmark/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/benchmark/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/benchmark/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/benchmark/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/benchmark/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/benchmark/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/benchmark/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/benchmark/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/benchmark/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/benchmark/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/benchmark/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/benchmark/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/benchmark/image15.jpg');
			}

		
		}
	}



	&#bentelli {
		& .fifth.product {
			width: 20%;
			height: 400px;
			@extend .product-block-hover;
			margin-bottom: 20px;
			&#product1 {
				@include background-image('/img/comp/bentelli/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/bentelli/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/bentelli/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/bentelli/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/bentelli/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/bentelli/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/bentelli/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/bentelli/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/bentelli/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/bentelli/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/bentelli/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/bentelli/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/bentelli/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/bentelli/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/bentelli/image15.jpg');
			}

		
		}
	}



	&#bulova {
		& .fifth.product {
			width: 20%;
			height: 400px;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('/img/comp/bulova/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/bulova/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/bulova/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/bulova/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/bulova/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/bulova/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/bulova/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/bulova/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/bulova/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/bulova/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/bulova/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/bulova/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/bulova/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/bulova/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/bulova/image15.jpg');
			}

		
		}
	}


	&#citizen {
		& .fifth.product {
			width: 20%;
			height: 400px;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('/img/comp/citizen/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/citizen/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/citizen/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/citizen/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/citizen/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/citizen/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/citizen/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/citizen/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/citizen/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/citizen/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/citizen/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/citizen/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/citizen/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/citizen/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/citizen/image15.jpg');
			}

		
		}
	}



	&#danielwellington {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('/img/comp/danielwellington/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/danielwellington/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/danielwellington/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/danielwellington/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/danielwellington/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/danielwellington/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/danielwellington/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/danielwellington/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/danielwellington/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/danielwellington/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/danielwellington/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/danielwellington/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/danielwellington/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/danielwellington/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/danielwellington/image15.jpg');
			}

		
		}
	}



	&#frankreubel {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 20px;
			&#product1 {
				@include background-image('/img/comp/frankreubel/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/frankreubel/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/frankreubel/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/frankreubel/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/frankreubel/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/frankreubel/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/frankreubel/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/frankreubel/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/frankreubel/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/frankreubel/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/frankreubel/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/frankreubel/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/frankreubel/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/frankreubel/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/frankreubel/image15.jpg');
			}

		
		}
	}



	&#gshock {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('/img/comp/gshock/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/gshock/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/gshock/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/gshock/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/gshock/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/gshock/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/gshock/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/gshock/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/gshock/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/gshock/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/gshock/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/gshock/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/gshock/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/gshock/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/gshock/image15.jpg');
			}

		
		}
	}



	&#kama {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 20px;
			&#product1 {
				@include background-image('/img/comp/kama/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/kama/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/kama/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/kama/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/kama/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/kama/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/kama/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/kama/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/kama/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/kama/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/kama/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/kama/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/kama/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/kama/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/kama/image15.jpg');
			}

		
		}
	}



	&#luminox {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('/img/comp/luminox/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/luminox/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/luminox/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/luminox/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/luminox/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/luminox/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/luminox/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/luminox/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/luminox/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/luminox/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/luminox/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/luminox/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/luminox/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/luminox/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/luminox/image15.jpg');
			}

		
		}
	}


	&#nataliek {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 20px;
			&#product1 {
				@include background-image('/img/comp/nataliek/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/nataliek/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/nataliek/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/nataliek/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/nataliek/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/nataliek/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/nataliek/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/nataliek/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/nataliek/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/nataliek/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/nataliek/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/nataliek/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/nataliek/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/nataliek/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/nataliek/image15.jpg');
			}

		
		}
	}




	&#pandora {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 20px;
			&#product1 {
				@include background-image('/img/comp/pandora/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/pandora/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/pandora/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/pandora/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/pandora/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/pandora/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/pandora/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/pandora/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/pandora/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/pandora/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/pandora/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/pandora/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/pandora/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/pandora/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/pandora/image15.jpg');
			}

		
		}
	}



	&#tissot {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('/img/comp/tissot/image1.jpg');
			}
			&#product2 {
				@include background-image('/img/comp/tissot/image2.jpg');
			}
			&#product3 {
				@include background-image('/img/comp/tissot/image3.jpg');
			}
			&#product4 {
				@include background-image('/img/comp/tissot/image4.jpg');
			}
			&#product5 {
				@include background-image('/img/comp/tissot/image5.jpg');
			}

			&#product6 {
				@include background-image('/img/comp/tissot/image6.jpg');
			}
			&#product7 {
				@include background-image('/img/comp/tissot/image7.jpg');
			}
			&#product8 {
				@include background-image('/img/comp/tissot/image8.jpg');
			}
			&#product9 {
				@include background-image('/img/comp/tissot/image9.jpg');
			}
			&#product10 {
				@include background-image('/img/comp/tissot/image10.jpg');
			}

			&#product11 {
				@include background-image('/img/comp/tissot/image11.jpg');
			}
			&#product12 {
				@include background-image('/img/comp/tissot/image12.jpg');
			}
			&#product13 {
				@include background-image('/img/comp/tissot/image13.jpg');
			}
			&#product14 {
				@include background-image('/img/comp/tissot/image14.jpg');
			}
			&#product15 {
				@include background-image('/img/comp/tissot/image15.jpg');
			}

		
		}
	}



	&#versace {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/1.jpg');
			}
			&#product2 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/8.jpg');
			}
			&#product3 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/10.jpg');
			}
			&#product4 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/15.jpg');
			}
			&#product5 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/20.jpg');
			}

			&#product6 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/22.jpg');
			}
			&#product7 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/26.jpg');
			}
			&#product8 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/28.jpg');
			}
			&#product9 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/30.jpg');
			}
			&#product10 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/32.jpg');
			}

			&#product11 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/34.jpg');
			}
			&#product12 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/36.jpg');
			}
			&#product13 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/38.jpg');
			}
			&#product14 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/35.jpg');
			}
			&#product15 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versace/42.jpg');
			}

		
		}
	}


	&#versus {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/1.jpg');
			}
			&#product2 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/8.jpg');
			}
			&#product3 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/10.jpg');
			}
			&#product4 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/15.jpg');
			}
			&#product5 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/20.jpg');
			}

			&#product6 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/22.jpg');
			}
			&#product7 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/26.jpg');
			}
			&#product8 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/28.jpg');
			}
			&#product9 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/30.jpg');
			}
			&#product10 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/32.jpg');
			}

			&#product11 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/34.jpg');
			}
			&#product12 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/36.jpg');
			}
			&#product13 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/38.jpg');
			}
			&#product14 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/35.jpg');
			}
			&#product15 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/versus/42.jpg');
			}

		
		}
	}


	&#scott-kay {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image1.jpg');
			}
			&#product2 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image8.jpg');
			}
			&#product3 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image10.jpg');
			}
			&#product4 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image15.jpg');
			}
			&#product5 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image20.jpg');
			}

			&#product6 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image22.jpg');
			}
			&#product7 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image26.jpg');
			}
			&#product8 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image28.jpg');
			}
			&#product9 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image30.jpg');
			}
			&#product10 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image32.jpg');
			}

			&#product11 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image34.jpg');
			}
			&#product12 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image36.jpg');
			}
			&#product13 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image38.jpg');
			}
			&#product14 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image35.jpg');
			}
			&#product15 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/scott-kay/image40.jpg');
			}

		
		}
	}


	&#shy-creations {
		& .fifth.product {
			width: 20%;
			height: 400px;
			background-color: #fff;
			@extend .product-block-hover;
			margin-bottom: 40px;
			&#product1 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/1.jpg');
			}
			&#product2 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/8.jpg');
			}
			&#product3 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/10.jpg');
			}
			&#product4 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/15.jpg');
			}
			&#product5 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/20.jpg');
			}

			&#product6 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/22.jpg');
			}
			&#product7 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/26.jpg');
			}
			&#product8 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/28.jpg');
			}
			&#product9 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/30.jpg');
			}
			&#product10 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/32.jpg');
			}

			&#product11 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/34.jpg');
			}
			&#product12 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/36.jpg');
			}
			&#product13 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/38.jpg');
			}
			&#product14 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/35.jpg');
			}
			&#product15 {
				@include background-image('https://s3.amazonaws.com/z-fine-jewelry/products/shy-creations/39.jpg');
			}

		
		}
	}

}