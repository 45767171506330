.page {
	padding-top: 154px;
	padding-bottom: 60px;
	&.location {
		padding-top: 94px;
		padding-bottom: 0px;
	}
	& h3 {
		margin-bottom: 40px;
	}
	& .center-img {
		margin: 0 auto;
		display: block;
		max-width: 100%;
	}
	& .row {
		margin-bottom: 0;
	}
}

.info-header {
	width: 100%;
	display: block;
	height: 250px;
	&#hawthorn {
		@include background-image('/img/loc/hawthorn/locationheader.jpg');
	}
	&#gurnee {
		@include background-image('/img/loc/gurnee/locationheader.jpg');
	}
}

.info {
	padding: 60px 0;
	& h3 {
		margin-top: 0;
	}
	& .address {
		list-style: none;
		& li {
			text-align: center;
			font-size: 1.4rem;
			color: #666;
		}
	}
}


// About Page
.about {
	padding-top: 15px;
/* 	& h3 {
		margin-top:
	} */
}
.col.about-block {
	height: 550px;
}

.col.about-left {
	padding: 60px 200px;
	& p, ul li {
		font-family: $secondfont;
	}
	& ul {
		padding-left: 0px;
	}
}

.col.about-right {
	padding: 0;
	& p, ul li {
		font-family: $secondfont;
		font-size: 1.3rem;
	}
	& .overlay {
		padding: 60px 200px;
		width: 100%;
		height: 100%;

	}
	&#gurnee-about-bg {
		@include background-image('/img/loc/gurnee/about.jpg');
	}
	&#hawthorn-about-bg {
		@include background-image('/img/loc/hawthorn/about.jpg');
	}
}

.about-left {
	background-color: #fff;
}
.about-right {
	background-color: #407dac;
	color: #fff;
}


// Specials Page

.grid {
	max-width: 1210px;
	margin: 0 auto;
	& .brick {
		display: inline-block;
	}
}

.responsive-img.centered {
	margin: 0 auto;
	float: none;
	display: block;
}