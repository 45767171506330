.section {
	&.head {
		&#hawthorn-head {
			height: 400px;
			@include background-image('/img/loc/hawthorn/head.jpg');
		}
		&#gurnee-head {
			height: 400px;
			@include background-image('/img/loc/gurnee/head.jpg');
		}
		& .right-block {
			width: 425px;
			height: 60%;
			background-color: rgba(64,98,147,0.5);
			position: absolute;
			right: 270px;
			top: 0;
			bottom: 0;
			margin: auto;
			text-align: center;
			text-transform: uppercase;
			font-size: 2rem;
			& > div {
				width: 97%;
				height: 95%;
				position: absolute;
				left: 1.5%;
				top: 2.5%;
				padding: 40px;
				display: block;
				border: 1px solid rgba(255,255,255, 0.6);
				& > div {
					height: 100%;
				}
			}
		}
	}
	&.brands {
		padding: 40px 0 60px 0;
		& h3 {
			margin-bottom: 60px;
		}
		& .brands-slide {
			
		}
		& .slick-slide {
			overflow: hidden;
			& .brand {
				padding: 0 100px;
				max-width: 100%;
				margin: 0 auto;
			}
		}
	}
	&.products {
		padding: 0;
		& .row {
			margin: 0;
			}
			& .product {
				height: 350px;
				&#gproduct1{
					@include background-image('/img/products/gurnee/Gia.jpg');
				}
				&#gproduct2{
					@include background-image('/img/products/gurnee/Pandora.jpg');
				}
				&#gproduct3{
					@include background-image('/img/products/gurnee/Aquamaster_Watches.jpg');
				}	
				&#gproduct4{
					@include background-image('/img/products/gurnee/Benchmark_Wedding_Bands.jpg');
				}
				&#gproduct5{
					@include background-image('/img/products/gurnee/Citizen_Watches.jpg');
				}
				&#gproduct6{
					@include background-image('/img/products/gurnee/Bentelli_Diamond_Jewelry.jpg');
				}	
				&#gproduct7{
					@include background-image('/img/products/gurnee/product1.jpg');
				}
				&#gproduct8{
					@include background-image('/img/products/gurnee/Kama_Love_Collection.jpg');
				}
				&#gproduct9{
					@include background-image('/img/products/gurnee/G-Shock_Watches.jpg');
				}
				&#gproduct10{
					@include background-image('/img/products/gurnee/Tissot_Watches.jpg');
				}
				&#gproduct11{
					@include background-image('/img/products/gurnee/product5.jpg');
				}
				&#gproduct12{
					@include background-image('/img/products/gurnee/Bulova_Watches.jpg');
				}
				&#gproduct13{
					@include background-image('/img/products/gurnee/Agni_Natural_Gemstones.jpg');
				}
				&#gproduct14{
					@include background-image('/img/products/gurnee/Luminox_Watches.jpg');
				}
				&#gproduct15{
					@include background-image('/img/products/gurnee/Daniel_Wellington.jpg');
				}	


				&#hproduct1{
					@include background-image('/img/products/hawthorn/product1.jpg');
				}
				&#hproduct2{
					@include background-image('/img/products/hawthorn/product2.jpg');
				}
				&#hproduct3{
					@include background-image('/img/products/hawthorn/product3.jpg');
				}	
				&#hproduct4{
					@include background-image('/img/products/hawthorn/product4.jpg');
				}
				&#hproduct5{
					@include background-image('/img/products/hawthorn/product5.jpg');
				}
				&#hproduct6{
					@include background-image('/img/products/hawthorn/product6.jpg');
				}	
				&#hproduct7{
					@include background-image('/img/products/hawthorn/product7.jpg');
				}
				&#hproduct8{
					@include background-image('/img/products/hawthorn/product8.jpg');
				}
				&#hproduct9{
					@include background-image('/img/products/hawthorn/product9.jpg');
				}	
				&#hproduct10{
					@include background-image('/img/products/hawthorn/product10.jpg');
				}
				&#hproduct11{
					@include background-image('/img/products/hawthorn/product11.jpg');
				}
				&#hproduct12{
					@include background-image('/img/products/hawthorn/product12.jpg');
				}	
				&#hproduct13{
					@include background-image('/img/products/hawthorn/product13.jpg');
				}
				&#hproduct14{
					@include background-image('/img/products/hawthorn/product14.jpg');
				}
				&#hproduct15{
					@include background-image('/img/products/hawthorn/product15.jpg');
				}	
			}
	}
	&.map {
		padding: 0;
		height: 500px;
	}
	& .links {
		& .footer-links a {
			color: #717171;
			text-transform: uppercase;
			font-size: 1.2rem;
			padding: 0 5px;
			&:after {
				content: '';
				height: 10px;
				width: 10px;
				margin-bottom: 1px;
				display: inline-block;
				margin-left: 10px;
				@include background-image('/img/ui/diamonds.png');
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			&:hover {
				color: $lightblue;
			}
		}
	}
}