.message {
	text-align: center;
	position: relative;
	color: #fff;
	padding: 10px;
	width: 100%;
	margin: 20px auto;
	clear: both;
	border-radius: 10px;
	& p {
		color: #fff;
		font-size: 1.3rem;
		margin: 10px 0;
	}
}
.success {
	@extend .message;
	background-color: green;
}

.error {
	@extend .message;
	background-color: red;
}